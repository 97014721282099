import React, { Component } from "react";
import "./footer.scss";
import Logo from "../../../public/img/ale-baja-logo-white.svg";
import { withRouter } from "react-router-dom";

class Footer extends Component { 
  render() {
    return (
      <div>
        <footer className="footer">
          <div className="footer__logo-box">
            <img
              className="footer__logo"
              onClick={() => {
                window.scrollTo(0, 0);
                this.props.history.push("/")
              }}
              alt="Footer-logo"
              src={Logo}
            ></img>
          </div>
          <div className="row">
            <p className="footer__copyright">
              Copyright &copy; 2025 alebaja.com.pl
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(Footer);
